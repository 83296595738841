// const url = "https://nodevalidatorpune-cryptowallet.mobiloitte.io/api/v1"; // Staging

const url = "https://node.nodescan.org/api/v1"; // Live
// const url = "http://172.16.1.165:3062/api/v1";

const user = `${url}/user`;
const statics = `${url}/statics`;

const ApiConfig = {
  connectWallet: `${user}/connect-wallet`,
  viewUser: `${user}/viewUser`,
  updatedWallet: `${user}/otp-update-wallet-address`,
  verifyOtpUpdateWalletAddress: `${user}/verify-otp-update-wallet-address`,
  BuyNode: `${user}/buy-node`,
  subscribeToNewsLetter: `${user}/subscribeToNewsLetter`,

  // register
  signup: `${user}/register`,
  verifyOTP: `${user}/verify-otp`,
  forgotPassword: `${user}/forgot-password`,
  resetPassword: `${user}/reset-password`,
  login: `${user}/login`,
  changePassword: `${user}/change-password`,

  // profile
  viewUser: `${user}/view-profile`,
  updateProfile: `${user}/edit-profile`,

  //Dashboard Data
  StateDashboard: `${user}/state-dashboard`,

  // Trasaction History
  UserTransactions: `${user}/user-transactions`,

  // User Nodes details
  UserNodes: `${user}/user-nodes`,
  ListNodes: `${user}/list-nodes`,

  // Static Content
  GetAll: `${statics}/get-single`,
  // Plan reactive
  reactiveNode: `${user}/reactive-node`,
};

export default ApiConfig;

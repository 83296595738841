import { makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  loaderContainer: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)" /* Adjust transparency here */,
    backdropFilter: "blur(5px)" /* Add blur effect here */,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 9999,
  },
  loader: {
    border: "6px solid #f3f3f3",
    borderTop: "6px solid #3498db",
    borderRadius: "50%",
    width: "50px",
    height: "50px",
    animation: "$spin 2s linear infinite",
  },
  "@keyframes spin": {
    "0%": { transform: "rotate(0deg)" },
    "100%": { transform: "rotate(360deg)" },
  },
}));

const PageLoaderComponent = ({ loading }) => {
  const classes = useStyles();

  return (
    loading && (
      <div className={classes.loaderContainer}>
        <div className={classes.loader}></div>
      </div>
    )
  );
};

export default PageLoaderComponent;

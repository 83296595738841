import React, { useState } from "react";
import { NavLink as RouterLink } from "react-router-dom";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Button, Collapse, ListItem, makeStyles, Box } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";

const useStyles = makeStyles((theme) => ({
  item: {
    display: "block",
    paddingTop: 0,
    paddingBottom: 0,
  },
  itemLeaf: {
    display: "flex",
    paddingTop: 0,
    paddingBottom: 0,
    "& .MuiButton-label": {
      display: "flex",
      alignItems: "center",
      justifyContent: "left",
    },
  },
  button: {
    // color: "#000000",
    color: theme.palette.text.primary,
    padding: "10px 24px",
    justifyContent: "flex-start",
    textTransform: "none",
    letterSpacing: 0,
    width: "100%",
  },
  buttonLeaf: {
    // color: theme.palette.text.primary,
    // padding: "17px 8px",
    justifyContent: "center",
    textTransform: "none",
    letterSpacing: 0,
    width: "100%",
    whiteSpace: "pre",
    // borderLeft: "solid 8px transparent",
    borderRadius: 0,

    fontFamily: "Poppins",
    fontStyle: "normal",
    // fontWeight: "400",
    fontSize: "17px",
    lineHeight: "30px",
    textAlign: "center",

    color: "#FFFFFF",
    "& $title": {
      fontWeight: 400,
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontSize: "14px",
      lineHeight: "30px",
    },
    "& .MuiButton-label": {
      padding: "10px",
    },
    "&:hover": {
      "& .MuiButton-label": {
        color: "#fff !important",
        background: "black",
        padding: "10px",
        // borderRadius: "9px",
        fontWeight: theme.typography.fontWeightRegular,

        "& $icon": {
          color: "#fff !important",
          // color: "00e0b0",
        },
      },
    },
    "&.depth-0": {
      "& $title": {
        fontWeight: theme.typography.fontWeightMedium,
        // color: `${theme.palette.text.primary} !important`,
      },
    },
  },
  icon: {
    display: "flex",
    alignItems: "center",
    marginRight: "12px",
    marginLeft: "8px",
    color: "white !important",
  },
  // title: {
  //   marginRight: "auto",
  // },
  active: {
    "& .MuiButton-label": {
      color: "#16110D",
      background: "#FFBF00",
      padding: "10px",
      margin: "0 -7px",
      // borderRadius: "9px",
      fontWeight: theme.typography.fontWeightRegular,
      "& $title": {
        fontWeight: theme.typography.fontWeightMedium,
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontSize: "20px",
      },
      "& $icon": {
        color: "#fff !important",
        // color: `${theme.palette.text.primary} !important`,
      },
    },
  },
  sideItems: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const NavItem = ({
  children,
  className,
  depth,
  href,
  icon: Icon,
  info: Info,
  open: openProp,
  title,
  ...rest
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [open, setOpen] = useState(openProp);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  let paddingLeft = 8;

  if (depth > 0) {
    paddingLeft = 32 + 8 * depth;
  }

  const style = { paddingLeft };

  if (children) {
    return (
      <ListItem
        className={clsx(classes.item, className)}
        disableGutters
        key={title}
        {...rest}
      >
        <Button
          className={classes.button}
          onClick={handleToggle}
          // style={{ color: "#ccc" }}
        >
          {Icon && <Icon className={classes.icon} size="20" />}
          <span className={classes.title}>{title}</span>
          {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Button>
        <Collapse in={open}>{children}</Collapse>
      </ListItem>
    );
  }

  return (
    <>
      <ListItem
        className={clsx(classes.itemLeaf, className)}
        disableGutters
        key={title}
        {...rest}
      >
        <Button
          activeClassName={classes.active}
          className={clsx(classes.buttonLeaf, `depth-${depth}`)}
          component={RouterLink}
          exact
          style={style}
          to={href}
        >
          <Box className={classes.sideItems}>
            {Icon && <Icon className={classes.icon} size="20" />}
            <span className={classes.title}>{title}</span>
            {Info && <Info />}
          </Box>
        </Button>
      </ListItem>
    </>
  );
};

NavItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  depth: PropTypes.number.isRequired,
  href: PropTypes.string,
  icon: PropTypes.elementType,
  info: PropTypes.elementType,
  open: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

NavItem.defaultProps = {
  open: false,
};

export default NavItem;

///////////////////////////////Mainnet///////////////////////////

export const ACTIVE_NETWORK = 8998;
export const RPC_URL = "https://rpc1.chromescan.org/";
export const NetworkContextName = "Chromechain Mainnet";
export const tokenAddress = "0xc18ABdb1DE2d453F90f92295351B30c1D900dbbc";
export const adminAddress = "0x3eAe2546fFFC2650faF3ed752C0b7d2FA0d7d698";
export const cscPriceAPI =
  "https://dashboard.cccmining.org/api/tenant/b164ff9b-47d2-4b22-b3ec-3d0c69973188/exchange-rate?limit=1&api_key=79c9feda-7d18-47ee-bd82-c6e6f2221558-3cfe0363-352e-4e54-b1a9-5e9db7b0c89f";
export const explorerURL = "https://mainnet.chromescan.org/";
// //---#ACTIVE-NETWORK-DETAILS#---//
export const NetworkDetails = [
  {
    chainId: "0x2326",
    chainName: NetworkContextName,
    nativeCurrency: {
      name: "CSC",
      symbol: "CSC",
      decimals: 18,
    },
    rpcUrls: [RPC_URL],
    blockExplorerUrls: ["https://mainnet.chromescan.org/"],
  },
];

////////////////////////////Testnet///////////////////////////

// export const ACTIVE_NETWORK = 8999;
// export const RPC_URL = "https://rpc1-testnet.chromescan.org/";
// export const NetworkContextName = "Chromechain Mainnet";
// export const tokenAddress = "0x100D0339085E1AD3b5b8078e4b0A4046f16D4859"; ////CRT
// export const adminAddress = "0xf26f0Bf9AD45f8aC0Ca6ebE1484B2231837AE162";
// export const explorerURL = "https://mainnet.chromescan.org/";
// export const cscPriceAPI =
//   "https://dashboard.cccmining.org/api/tenant/b164ff9b-47d2-4b22-b3ec-3d0c69973188/exchange-rate?limit=1&api_key=79c9feda-7d18-47ee-bd82-c6e6f2221558-3cfe0363-352e-4e54-b1a9-5e9db7b0c89f";

// //---#ACTIVE-NETWORK-DETAILS#---//
// export const NetworkDetails = [
//   {
//     chainId: "0x2327",
//     chainName: NetworkContextName,
//     nativeCurrency: {
//       name: "CSC",
//       symbol: "CSC",
//       decimals: 18,
//     },
//     rpcUrls: [RPC_URL],
//     blockExplorerUrls: ["https://explorer-testnet.chromescan.org/"],
//   },
// ];

import React, { createContext, useState, useEffect, useContext } from "react";
import axios from "axios";
import ApiConfig, { baseUrl } from "src/config/APICongig";
import { AuthContext } from "./Auth";
import { useWeb3React } from "@web3-react/core";
import { injected } from "src/connectors";
import { ACTIVE_NETWORK, NetworkDetails, tokenAddress } from "src/constants";
import { getContract, getWeb3Obj } from "src/utils";
import IERC20ABI from "src/abi/IERC20ABI.json";


export const UserContext = createContext();
const setSession = (userAddress) => {
  if (userAddress) {
    sessionStorage.setItem("userAddress", userAddress);
  } else {
    sessionStorage.removeItem("userAddress");
  }
};

const setTokenSession = (token) => {
  if (token) {
    sessionStorage.setItem("token", token);
  } else {
    sessionStorage.removeItem("token");
  }
};

export default function AuthProvider(props) {
  // const socket = window?.io(baseUrl, {
  //   transports: ["websocket", "polling", "flashsocket"],
  // });

  const [isLogin, setIsLogin] = useState(false);
  const auth = useContext(AuthContext);
  const [yourWalletBalance, setYourWalletBalance] = useState(0);
  const [userBalanceData, setUserBalanceData] = useState([]);
  const [notificationList, setNotificationList] = useState([]);
  const [unReadNotification, setUnReadNotification] = useState(0);
  const [StatusData, setStatusData] = useState({});
  const [loader, setLoader] = useState(false);


  const { activate, account, chainId, deactivate, library } = useWeb3React();
 const [userWalletBalance, setUserWalletBalance] = useState(0);
  const [tokenBalance, setTokenBalance] = useState(0);

   //-----*WEB3-Connection-Establisher*-----//
   const connectWalletHandler = () => {
    try {
      const connector = injected;
      localStorage.setItem("walletName", "METAMASK");
      sessionStorage.removeItem("walletName");
      if (connector && connector.walletConnectProvider?.wc?.uri) {
        connector.walletConnectProvider = undefined;
      }
      activate(connector, undefined, true).catch((error) => {
        if (error) {
          localStorage.removeItem("walletName");
          // activate(connector);
          console.log("error", error);
        }
      });
    } catch (error) {
      console.log("ERROR", error);
      // toast.error(JSON.stringify(error.message));
    }
  };

   /*
  -----*WEB3-Connection-Disable*-----
   USE THIS FUNCTION IF YOU NEED TO ADD A DISCONNECT WALLET BUTTON
  */
   const disconnectWalletHandler = async () => {
    try {
      deactivate();

      window.localStorage.removeItem("walletName");
      window.open("/", "_self");
    } catch (error) {
      console.log(error);
    }
  };

//-----*WEB3-Network-Change-Request*-----//
  const swichNetworkHandler = async () => {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: "0x" + ACTIVE_NETWORK.toString(16) }],
      });
    } catch (error) {
      console.log("ERROR", error);
      if (error.code === 4902) {
        addNetworkHandler();
      }
    }
  };

//-----*WEB3-Network-Add-Request*-----//
const addNetworkHandler = async () => {
  try {
    await window.ethereum.request({
      method: "wallet_addEthereumChain",
      params: NetworkDetails,
    });
  } catch (error) {
    console.log("ERROR", error);
    // toast.warn(error.message);
  }
};

// account balance -----//
const getUserbalance = async () => {
  const web3 = await getWeb3Obj();
  const balance = await web3.eth.getBalance(account);

  const balanceImETH = await web3.utils.fromWei(balance);
  setUserWalletBalance(parseFloat(balanceImETH).toFixed(2));
};

const getTokenWalletBalance = async () => {
  try {
    const web3 = await getWeb3Obj();
    const tokenObj = getContract(tokenAddress, IERC20ABI, library, account);

    const name = await tokenObj.name();
    const getTokenBalance = await tokenObj.balanceOf(account);
    const balancetoken = web3.utils.fromWei(getTokenBalance?.toString());
    // console.log("tokenObj---", { tokenObj, account, balancetoken });
    setTokenBalance(balancetoken);
  } catch (error) {
    console.log(error);
  }

  // settokenBalance(parseFloat(balancetoken).toFixed(2));
};

useEffect(() => {
  if (account && chainId) {
    if (chainId !== ACTIVE_NETWORK) {
      window.scrollTo(0, 0);
      if (window.ethereum) {
        swichNetworkHandler();
      }
    }
  }
}, [chainId, account]); //eslint-disable-line

useEffect(() => {
  if (localStorage.getItem("walletName")) {
    connectWalletHandler();
  }
}, [localStorage.getItem("walletName")]);

useEffect(() => {
  if (account) {
    getTokenWalletBalance();
    getUserbalance();
    connectWallet(account)
  }
}, [account]);

const connectWallet = async(account)=>{
  try {
    const res = await axios({
      method: "POST",
      url: `${ApiConfig.connectWallet}`,
      headers: {
        token: window.localStorage.getItem("token")
      },
      params: {
        walletAddress: account
      },
    });
  } catch (error) {
  }
}

  const accessToken = localStorage.getItem("token");
  // useEffect(() => {
  //   socket.on("connection", function () {
  //     console.log("---connected---");
  //   });
  //   return () => {
  //     socket.close();
  //   };
  // }, []);

  const getWalletHandler = async (accessToken) => {
    try {
      const res = await axios({
        method: "GET",
        url: ApiConfig.myWallet,
        headers: {
          token: accessToken,
        },
      });

      if (res.data.responseCode === 200) {
        window.localStorage.getItem("coinName");
        setUserBalanceData(res.data.result);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const NotificationDataList = async () => {
    try {
      const res = await axios({
        method: "GET",
        url: ApiConfig.listNotificationAll,
        headers: {
          token: window.localStorage.getItem("token"),
        },
      });

      if (res.data.responseCode === 200) {
        setNotificationList(res.data.result.data);
        setUnReadNotification(res?.data?.result?.unReadCount);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    NotificationDataList();
  }, []);

  const getKycList = async () => {
    setLoader(true);
    try {
      const res = await axios.get(ApiConfig.getKycList, {
        headers: {
          token: window.localStorage.getItem("token"),
        },
      });
      if (res.data.responseCode === 200) {
        setStatusData(res.data.result);
        setLoader(false);
        auth.getProfileHandler();
      }
    } catch (error) {
      setLoader(false);
    }
  };
  useEffect(() => {
    getKycList();
  }, []);

  // notification
  // const getNotification = (id) => {
  //   if (id) {
  //     socket.emit("getNotificationList", { userId: id });
  //     socket.on("getNotificationList", (event) => {
  //       setNotificationList(event?.responseResult?.data);
  //       setUnReadNotification(event?.responseResult?.unReadCount);
  //     });
  //   }
  // };

  // useEffect(() => {
  //   getNotification(auth?.userData?._id);
  // }, [auth?.userData, accessToken]);

  useEffect(() => {
    if (accessToken) {
      getWalletHandler(accessToken);
    }
  }, [accessToken]);

  let data = {
    isLogin,
    yourWalletBalance,
    userBalanceData,
    NotificationDataList: () => NotificationDataList(),
    notificationList,
    unReadNotification,
    setStatusData,
    StatusData,

    userWalletBalance,
    tokenBalance,
    refreshBalance: () => {
      getTokenWalletBalance();
      getUserbalance();
    },
 connectWallet: () => connectWalletHandler(),
    disconnectWallet: () => disconnectWalletHandler(),
  };

  return (
    <UserContext.Provider value={data}>{props.children}</UserContext.Provider>
  );
}

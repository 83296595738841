import React, { useState, useContext, useEffect } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  AppBar,
  Box,
  Toolbar,
  makeStyles,
  Grid,
  Typography,
  TextField,
  InputAdornment,
  startAdornment,
  IconButton,
  Button,
} from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import Login from "src/views/auth/login";
import Register from "src/views/auth/register";
import OtpVerify from "src/views/auth/otpVerify";
import { UserContext } from "src/context/User";
import { sortAddress } from "src/utils/index";
import { useWeb3React } from "@web3-react/core";

const menu = [
  { name: "Home" },
  { name: "Electronics" },
  { name: "Furniture" },
  { name: "Fashion & Apparel" },
  { name: "Property" },
  { name: "Two wheeler" },
  { name: "Cars" },
  { name: "Services" },
  { name: "Pets" },
  { name: "Jobs" },
  { name: "Books" },
];
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
  },
  toolbar: {
    height: 70,
    paddingLeft: "0 !important",
    paddingRight: "0 !important",
  },
  upperHeader: {
    height: 52,
    paddingLeft: "100px",
    paddingRight: "100px",
  },
  TextBox: {
    borderRadius: "10px",
    background: theme.palette.background.taf,
    height: "42px",
    paddingRight: "27px !important",
    paddingLeft: "27px !important",
  },
  locationButton: {
    background: "none",
    border: "none",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "14px",
    color: "#0A2830",
  },
  iconDiv: {
    display: "flex",
    justifyContent: "space-around",
  },
  menu: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "16px",
    color: "#0A2830",
  },
  signIn: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "14px",
    color: "#D39B2D",
    cursor: "pointer",
  },
  title: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "46px",
    // lineHeight: "96px",
    color: "#FFFFFF",
    "@media (max-width: 600px)": {
      fontSize: "26px",
    },
  },
  subTitle: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "400",
    // fontSize: "20px",
    lineHeight: "30px",
    color: "#FFBF00",
    "@media (max-width: 600px)": {
      fontSize: "12px",
      lineHeight: "16px",
    },
    "@media (max-width: 400px)": {
      fontSize: "10px",
    },
  },
  btnconnectwallet: {
    background: "#D39B2D",
    borderRadius: "5px",
    border: "none",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500 !important",
    fontSize: "14px",
    lineHeight: "14px",
    color: "#0A2830",
    width: "100%",
    maxWidth: "150px",
    height: "42px",
  },
}));

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();
  const location = useLocation();
  const [loginOpen, setLoginOpen] = React.useState(false);
  const [registerOpen, setRegisterOpen] = React.useState(false);
  const [otpOpen, setOtpOpen] = React.useState(false);

  const handleLoginModal = () => {
    setLoginOpen(!loginOpen);
  };

  const handleRegisterModal = () => {
    setRegisterOpen(!registerOpen);
  };
  const handleOtpModal = () => {
    setOtpOpen(!otpOpen);
  };
  const [name, setName] = useState("");
  useEffect(() => {
    setName(window.localStorage.getItem("name"));
  }, [window.localStorage.getItem("name")]);

  return (
    <>
      <AppBar
        className={clsx(classes.root, className)}
        color="default"
        {...rest}
      >
        <Box
          style={{
            paddingLeft: "100px",
            paddingRight: "100px",
            background: "black",
            height: "120px",
          }}
        >
          {" "}
          <Toolbar className={classes.toolbar}>
            <TopBarDashboard />
          </Toolbar>
        </Box>
      </AppBar>
      // Login Modal code
      <Login handleLoginModal={handleLoginModal} open={loginOpen} />
      <Register
        handleLoginModal={handleLoginModal}
        handleRegisterModal={handleRegisterModal}
        handleOtpModal={handleOtpModal}
        open={registerOpen}
      />
      <OtpVerify handleOtpModal={handleOtpModal} open={otpOpen} />
    </>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
};
TopBar.defaultProps = {
  onMobileNavOpen: () => {},
};

export default TopBar;

export function TopBarDashboard() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const user = useContext(UserContext);
  const { account } = useWeb3React();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location?.pathname]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={8} sm={8}>
          <Box>
            <Typography className={classes.title}>
              {location.pathname == "/dashboard"
                ? "Dashboard"
                : location.pathname == "/node-activation"
                ? "Node Manager"
                : // : location.pathname == "/api-key"
                // ? "Api Key"
                location.pathname == "/order-history"
                ? "Transaction History"
                : location.pathname == "/profile"
                ? "Account Manager"
                : location.pathname == "/edit-profile"
                ? "Edit Account"
                : location.pathname == "/buy-node"
                ? "Buy Node"
                : location.pathname == "/change-password"
                ? "Change Password"
                : ""}
            </Typography>
            <Typography className={classes.subTitle}>
              {location.pathname == "/dashboard"
                ? "Real-time overview of all the key metrices"
                : location.pathname == "/node-activation"
                ? "Easily activate and deactivate your nodes with just few clicks"
                : // : location.pathname == "/api-key"
                // ? "Api Key"
                location.pathname == "/order-history"
                ? "List Of Your Transactions in real time"
                : location.pathname == "/profile"
                ? "Manage your details and password"
                : location.pathname == "/edit-profile"
                ? "Manage your details and password"
                : location.pathname == "/buy-node"
                ? "Easily buy your nodes with just few clicks"
                : location.pathname == "/change-password"
                ? "Manage your password "
                : ""}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={4} sm={4}>
          {location.pathname == "/buy-node" ? (
            <Box
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
                height: "100%",
              }}
            >
              <Button
                onClick={() => {
                  !account && user.connectWallet();
                }}
                className={classes.btnconnectwallet}
              >
                {account ? sortAddress(account) : "Connect Wallet"}
              </Button>
            </Box>
          ) : (
            ""
          )}
        </Grid>
      </Grid>
    </>
  );
}

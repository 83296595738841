import React, { useState, useContext, useEffect } from "react";
import "src/scss/main.css";
import {
  Box,
  Typography,
  TextField,
  Grid,
  Button,
  makeStyles,
  FormHelperText,
  FormControl,
  InputAdornment,
  IconButton,
  FormControlLabel,
  Checkbox,
  Input,
} from "@material-ui/core";
import {
  useHistory,
  useLocation,
  Link as RouterComponent,
} from "react-router-dom";
import { Form, Formik, Field } from "formik";
import * as yep from "yup";
import Axios from "axios";
import ApiConfig from "src/config/APICongig";
import { toast, ToastContainer } from "react-toastify";
import ButtonCircularProgress from "src/component/ButtonCircularProgress";
import { AuthContext } from "src/context/Auth";
import Page from "src/component/Page";
import moment from "moment";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import PageLoaderComponent from "src/component/PageLoaderComponent";

const useStyles = makeStyles((theme) => ({
  buttonbox: {
    fontFamily: "Inter !important",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    textAlign: "center",
    textTransform: "none !important",
    letterSpacing: "0.2px",
    color: "#16110D !important",
    lineHeight: "normal",
    background: "#FFBF00 !important",
    width: "100%",
    maxWidth: "460px",
    height: "50px",
    borderRadius: "5px",
  },
  buttonboxdisabled: {
    fontFamily: "Inter !important",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    textAlign: "center",
    textTransform: "none !important",
    letterSpacing: "0.2px",
    color: "#16110D !important",
    lineHeight: "normal",
    background: "#FFBF00 !important",
    width: "100%",
    maxWidth: "460px",
    height: "50px",
    borderRadius: "5px",
    opacity: "0.5",
  },
  TextBox: {
    background: "rgba(113, 113, 113, 0.30) !important",
    borderRadius: "5px",
    fontSize: "16px",
    height: "50px",
    color: "var(--Secondary-Grey, #929292) !important",
    "&::placeholder": {
      color: "#929292",
      fontFamily: "Inter !important",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "16px",
      lineHeight: "normal",
    },
    "& .MuiInputBase-input": {
      color: "#fff !important",
      fontFamily: "Inter !important",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "normal",
      height: "25px",
      marginLeft: "10px",
    },
  },
  wrongPassword: {
    borderRadius: "8px",
    border: "1px solid red",
    background: theme.palette.background.taf,
    height: "55px",
  },
  TitleSignUp: {
    color: "var(--White, #FFF)",
    fontFamily: "Inter",
    fontSize: "34px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "130%",
    letterSpacing: "-0.32px",
    // whiteSpace: "pre",
    // "@media(max-width:1080px)": {
    //   whiteSpace: "inherit",
    // },
    // "@media(max-width:960px)": {
    //   whiteSpace: "pre",
    // },

    // textTransform: "capitalize",
  },
  TopText: {
    display: "grid",
    gap: "10px",
    alignItems: "center",
  },
  linkText: {
    color: " #0B1426",
    fontFamily: "Poppins",
    fontSize: "14px !important",
    fontStyle: "normal",
    fontWeight: "500 !important",
    lineHeight: "20px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  SubmitBtnBox: {
    textAlign: "center",
  },
  label: {
    fontFamily: "Saira Semi Condensed",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "24px",
    marginTop: "10px !important",
    marginBottom: "5px !important",
    color: "#D9D9D9",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  rememberMe: {
    "&.MuiTypography-body1": {
      color: "var(--White, #FFF) !important",
      fontFamily: "Inter",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "500",
      lineHeight: "normal",
      [theme.breakpoints.down("sm")]: {
        fontSize: "12px",
      },
    },
  },
  textfiled: {
    fontFamily: "Inter !important",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "normal",
    color: " #929292 !important",
    background: "rgba(113, 113, 113, 0.30) !important",
    borderRadius: "0px",
  },

  labelall: {
    color: "var(--White, #FFF)",
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "normal",
  },
  checkedlogin: {
    "&.MuiCheckbox-colorSecondary.Mui-checked": {
      color: "var(--White, #FFF) !important",
    },
    "&.MuiCheckbox-root": {
      color: "var(--White, #FFF) !important",
    },
  },
  dontacctypo: {
    color: "var(--White, #FFF)",
    textAlign: "center",
    fontFamily: "Inter",
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "24px",
  },
  typostaticcontent: {
    color: "var(--White, #FFF)",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
    marginLeft: "5px",
  },
  customInput: {
    "&::placeholder": {
      color: "#fff !important", // Change the placeholder text color
    },
  },
  passsec: {
    "& svg": {
      color: "#fff",
    },
  },
}));

function Signup(props) {
  const auth = useContext(AuthContext);
  const location = useLocation();
  const classes = useStyles();
  const history = useHistory();
  // getting invite code query string
  const [isloading, setLoader] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setshowConfirmPassword] = useState(false);
  const [wrongPass, setWrongPass] = useState(false);
  const [errorUser, setErrorUser] = useState(false);
  const [isRememberMe, setIsRememberMe] = useState(false);
  const [countryCode, setCountryCode] = useState(["in"]);
  const [selectedCountryCode, setSelectedCountryCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const handleFormSubmit = async (values) => {
    sessionStorage.setItem("email", values.email);
    setLoader(true);
    try {
      const res = await Axios({
        method: "POST",
        url: ApiConfig.signup,
        data: {
          name: values.name,
          email: values.email,
          // countryCode: values.countryCode,
          // mobileNumber: values.mobileNumber,
          password: values.password,
          confirmPassword: values.confirmPassword,
        },
      });
      if (res.data.responseCode === 200) {
        toast.success(
          "OTP sent successfully to your registered email address."
        );
        history.push({
          pathname: "/otp-verify",
          state: { email: values.email },
        });
        auth.setEndtime(moment().add(3, "m").unix());
      } else {
        toast.warn(res.data.responseMessage);
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      if (error.response.data.responseMessage) {
        toast.error(error.response.data.responseMessage);
        history.push({
          pathname: "/otp-verify",
          state: { email: values.email },
        });
        auth.setEndtime(moment().add(3, "m").unix());
      } else {
        toast.error("Something went wrong. Please try again.");
      }
    }
  };

  return (
    <>
      <Page title="Sign Up">
        <Grid className=" height100">
          <Box>
            <Box>
              <Grid container direction={"column"}>
                <Grid item className={classes.TopText}>
                  <Typography variant="h2" className={classes.TitleSignUp}>
                    Hello! Register to get started
                  </Typography>
                </Grid>

                <Formik
                  onSubmit={(values) => handleFormSubmit(values)}
                  initialValues={{
                    name: "",
                    email: "",
                    // countryCode: "",
                    // mobileNumber: "",
                    password: "",
                    confirmPassword: "",
                    // checkbox: "",
                  }}
                  initialStatus={{
                    success: false,
                    successMsg: "",
                  }}
                  validationSchema={yep.object().shape({
                    name: yep
                      .string()
                      .required("Please enter your Name.")
                      .min(2, "The name must be at least 2 characters long.")
                      .max(256, "Maximum 256 character."),
                    email: yep
                      .string()
                      .email(
                        "You have entered an invalid email address. Please try again"
                      )
                      .required("Please enter your email address.")
                      .matches("^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$"),
                    // countryCode: yep
                    //   .string()
                    //   .matches(/^\d+$/, 'Country code must be a number.'),
                    // mobileNumber: yep
                    //   .string()
                    //   .matches(/^\d+$/, 'Mobile number must be a number.'),
                    password: yep
                      .string()
                      .max(16)
                      .min(8, "Password must be at least 8 characters")
                      .matches(
                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[a-zA-Z\d@$!%*?&#]{8,16}$/,
                        "Please enter a password that is at least 8 characters long and contains at least one uppercase letter, one lowercase letter, and one number."
                      )
                      .required("Please enter your password"),
                    confirmPassword: yep
                      .string()
                      .required("Please enter confirm password")
                      .oneOf(
                        [yep.ref("password"), null],
                        "Passwords should match"
                      ),
                  })}
                >
                  {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    touched,
                    values,
                    setFieldValue,
                  }) => (
                    <Form style={{ marginTop: "10px" }}>
                      <Grid item className={classes.textFieldgrid}>
                        <Box>
                          <label className={classes.labelall}>
                            Full Name
                            <span style={{ color: "#EA4335" }}>*</span>
                          </label>
                          <TextField
                            placeholder="Enter your first name"
                            size="small"
                            variant="outlined"
                            fullWidth
                            inputProps={{ maxLength: 256, minLength: 2 }}
                            className={classes.TextBox}
                            type="text"
                            value={values.name}
                            // InputProps={{
                            //   className: classes.textfiled,
                            // }}
                            name="name"
                            error={Boolean(touched.name && errors.name)}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            InputProps={{
                              className: classes.TextBox,
                              classes: { input: classes.customInput },
                            }}
                          />
                          <FormHelperText
                            error
                            style={{
                              fontSize: "12px",
                              fontFamily: "Inter",
                            }}
                          >
                            {touched.name && errors.name}
                          </FormHelperText>
                        </Box>
                      </Grid>

                      <Grid item>
                        <label className={classes.labelall}>
                          Email <span style={{ color: "#EA4335" }}>*</span>
                        </label>
                        <TextField
                          placeholder="Enter your email."
                          type="email"
                          variant="outlined"
                          fullWidth
                          size="small"
                          inputProps={{ maxLength: 256 }}
                          value={values.email}
                          name="email"
                          className={classes.TextBox}
                          error={Boolean(touched.email && errors.email)}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          InputProps={{
                            className: classes.TextBox,
                            classes: { input: classes.customInput },
                          }}
                        />
                        <FormHelperText
                          error
                          style={{
                            fontSize: "12px",
                            fontFamily: "Inter",
                          }}
                        >
                          {touched.email && errors.email}
                        </FormHelperText>
                      </Grid>

                      <Grid item>
                        <FormControl fullWidth>
                          <Box
                            style={{ width: "100%" }}
                            className={classes.loginForm1}
                          >
                            <label className={classes.labelall}>
                              Password{" "}
                              <span style={{ color: "#EA4335" }}>*</span>
                            </label>
                            <TextField
                              placeholder="Enter your password."
                              size="small"
                              variant="outlined"
                              fullWidth
                              id="inputID"
                              type={showPassword ? "text" : "password"}
                              value={values.password}
                              inputProps={{ minLength: 8, maxLength: 16 }}
                              name="password"
                              error={Boolean(
                                touched.password && errors.password
                              )}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              InputProps={{
                                classes: { input: classes.customInput },
                                className:
                                  wrongPass === true
                                    ? classes.wrongPassword
                                    : classes.TextBox,
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      style={{ color: "#fff" }}
                                      onClick={() =>
                                        setShowPassword(!showPassword)
                                      }
                                      edge="end"
                                    >
                                      <>
                                        {showPassword ? (
                                          <Visibility color="white" />
                                        ) : (
                                          <VisibilityOff color="white" />
                                        )}
                                      </>
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                            <FormHelperText
                              error
                              style={{
                                fontSize: "12px",
                                fontFamily: "Inter",
                              }}
                            >
                              {touched.password && errors.password}
                            </FormHelperText>
                          </Box>
                        </FormControl>
                      </Grid>

                      <Grid item>
                        <FormControl fullWidth>
                          <Box
                            style={{ width: "100%" }}
                            className={classes.loginForm1}
                          >
                            <label className={classes.labelall}>
                              Confirm Password{" "}
                              <span style={{ color: "#EA4335" }}>*</span>
                            </label>
                            <TextField
                              placeholder="Enter your confirm password."
                              size="small"
                              variant="outlined"
                              fullWidth
                              id="inputID"
                              type={showConfirmPassword ? "text" : "password"}
                              value={values.confirmPassword}
                              inputProps={{ minLength: 8, maxLength: 16 }}
                              name="confirmPassword"
                              error={Boolean(
                                touched.confirmPassword &&
                                  errors.confirmPassword
                              )}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              InputProps={{
                                classes: { input: classes.customInput },
                                className:
                                  wrongPass === true
                                    ? classes.wrongPassword
                                    : classes.TextBox,
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      style={{ color: "#fff" }}
                                      onClick={() =>
                                        setshowConfirmPassword(
                                          !showConfirmPassword
                                        )
                                      }
                                      edge="end"
                                    >
                                      <>
                                        {showConfirmPassword ? (
                                          <Visibility color="white" />
                                        ) : (
                                          <VisibilityOff color="white" />
                                        )}
                                      </>
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                            <FormHelperText
                              error
                              style={{
                                fontSize: "12px",
                                fontFamily: "Inter",
                              }}
                            >
                              {touched.confirmPassword &&
                                errors.confirmPassword}
                            </FormHelperText>
                          </Box>
                        </FormControl>
                      </Grid>

                      <Grid
                        item
                        style={{
                          display: "grid",
                          alignItems: "center",
                          marginLeft: "8px",
                          marginTop: "-6px",
                        }}
                      >
                        <FormControlLabel
                          className={classes.rememberMe}
                          control={
                            <Checkbox
                              name="rememberMe"
                              onChange={handleChange}
                              checked={values.rememberMe}
                              className={classes.checkedlogin}
                            />
                          }
                          label={
                            <Typography className={classes.typostaticcontent}>
                              I have read, understand and agree to the{" "}
                              <span
                                style={{
                                  color: "#FFBE2E",
                                  cursor: "pointer",
                                  fontWeight: "500",
                                  textDecoration: "underline",
                                }}
                                onClick={() => {
                                  // history.push("/terms&condition");
                                  window.open("/terms&condition", "_blank");
                                }}
                              >
                                Terms & Conditions
                              </span>
                              &nbsp; and
                              <span
                                style={{
                                  color: "#FFBE2E",
                                  cursor: "pointer",
                                  fontWeight: "500",
                                  textDecoration: "underline",
                                }}
                                onClick={() => {
                                  // history.push("/privacy&policy");
                                  window.open("/privacy&policy", "_blank");
                                }}
                              >
                                {" "}
                                Privacy Policy.
                              </span>{" "}
                            </Typography>
                          }
                        />
                      </Grid>

                      <Grid>
                        <Box className={classes.SubmitBtnBox} mt={2}>
                          <Button
                            type="submit"
                            // className={classes.buttonbox}
                            // disabled={isloading}
                            disabled={
                              values.name === "" ||
                              values.email === "" ||
                              values.password === "" ||
                              values.confirmPassword === "" ||
                              values.rememberMe === "" ||
                              isloading
                            }
                            className={
                              values.name === "" ||
                              values.email === "" ||
                              values.password === "" ||
                              values.confirmPassword === "" ||
                              values.rememberMe === "" ||
                              isloading
                                ? classes.buttonboxdisabled
                                : classes.buttonbox
                            }
                          >
                            Sign Up
                            {isloading && <ButtonCircularProgress />}
                          </Button>
                        </Box>

                        <PageLoaderComponent loading={isloading} />
                        <Box
                          style={{
                            display: "grid",
                            justifyContent: "center",
                            marginTop: "16px",
                          }}
                        >
                          <Typography classname={classes.dontacctypo}>
                            Already have an account?{" "}
                            <span
                              onClick={() => {
                                history.push("/");
                              }}
                              style={{
                                cursor: "pointer",
                                fontWeight: "600",
                                color: "#FFF",
                                borderBottom: "1px solid white",
                              }}
                            >
                              Login
                            </span>
                          </Typography>
                        </Box>
                      </Grid>
                    </Form>
                  )}
                </Formik>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Page>
    </>
  );
}

export default Signup;

import React, { useState, useContext } from "react";
import "src/scss/main.css";
import {
  Box,
  Typography,
  FormControl,
  Grid,
  makeStyles,
  Button,
  FormHelperText,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { Form, Formik } from "formik";
import * as yep from "yup";
import { toast } from "react-toastify";
import axios from "axios";
import ApiConfig from "src/config/APICongig";
import { CircularProgress } from "@material-ui/core";
import ButtonCircularProgress from "src/component/ButtonCircularProgress";
import moment from "moment";
import { AuthContext } from "src/context/Auth";
import Page from "src/component/Page";
import OTPInput from "otp-input-react";
import PageLoaderComponent from "src/component/PageLoaderComponent";

const useStyles = makeStyles((theme) => ({
  logosec: {
    textAlign: "center",
    paddingTop: "26px",
    "@media(min-width:1280px)": {
      // display: "none",
    },
  },
  TextBox: {
    borderRadius: "10px",
    background: "#171717",
    height: "50px",
    border: "1px solid #585757",
    "&::placeholder": {
      color: "#686464 !important",
      fontFamily: "Saira Semi Condensed",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "10px",
      lineHeight: "24px",
    },
  },
  TopText: {
    textAlign: "center",
  },
  TitleOtp: {
    // color: "var(--White, #FFF)",
    fontFamily: "Inter",
    fontSize: "31px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
    letterSpacing: "0.3px",
    // textTransform: "capitalize",
  },
  subTitleOtp: {
    marginTop: "15px",
    marginLeft: "29px",
    // color: "var(--Secondary-Grey, #929292)",
    fontFamily: "Inter",
    fontSize: "17px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
    textAlign: "start",
  },
  buttonboxOtp: {
    fontFamily: "Inter !important",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    textAlign: "center",
    textTransform: "none !important",
    letterSpacing: "0.2px",
    color: "#16110D !important",
    lineHeight: "normal",
    background: "#FFBF00 !important",
    width: "100%",
    maxWidth: "430px",
    height: "50px",
    borderRadius: "5px",
  },
  buttonboxdisabled: {
    fontFamily: "Inter !important",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    textAlign: "center",
    textTransform: "none !important",
    letterSpacing: "0.2px",
    color: "#16110D !important",
    lineHeight: "normal",
    background: "#FFBF00 !important",
    width: "100%",
    maxWidth: "430px",
    height: "50px",
    borderRadius: "5px",
    opacity: "0.5",
  },
  label: {
    fontFamily: "Saira Semi Condensed",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "24px",
    marginTop: "15px !important",
    marginBottom: "6px !important",
    color: "#D9D9D9",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  otpFormControl: {
    "& input": {
      color: "#fff",
      width: "44px !important",
      height: "44px !important",
      marginRight: "10px !important",
      border: "1px solid rgb(11, 20, 38, 0.5)",
      borderRadius: "8px",
      backgroundColor: "rgba(113, 113, 113, 0.30)",
      "@media(max-width:460px)": {
        width: "41px !important",
        height: "41px !important",
      },
      "@media(max-width:380px)": {
        width: "31px !important",
        height: "31px !important",
      },
    },
  },
  SubmitBtnBox: {
    textAlign: "center",
  },
}));

function VerifyLoginOtp({ type }) {
  const history = useHistory();
  const auth = useContext(AuthContext);
  const classes = useStyles();
  const [isLoading, setLoader] = useState(false);
  const [otp, setOTP] = useState("");

  const handleFormSubmit = async (values) => {
    try {
      setLoader(true);
      const res = await axios({
        method: "POST",
        url: ApiConfig.verifyOTP,
        data: {
          otp: otp.toString(),
          email: window.sessionStorage.getItem("email"),
        },
      });

      if (res.data.responseCode === 200) {
        toast.success(res.data.responseMessage);
        setLoader(false);
        auth.userLogIn(true, res.data?.result?.token);
        window.localStorage.setItem("token", res.data.result.token);
        history.push("/dashboard");
      } else {
        setLoader(false);
        toast.warn(res.data.responseMessage);
      }
    } catch (error) {
      setLoader(false);
      if (error.response.data.responseMessage) {
        toast.error(error.response.data.responseMessage);
      } else {
        toast.error("Something went wrong. Please try again.");
      }
    }
  };

  const resetotphandle = async (values) => {
    setOTP("");
    try {
      setLoader(true);
      const res = await axios({
        method: "POST",
        url: ApiConfig.forgotPassword,
        data: {
          email: sessionStorage.getItem("email"),
        },
      });
      if (res.data.responseCode === 200) {
        toast.success("OTP resent successfully, Please check your email.");
        setLoader(false);
        auth.setEndtime(moment().add(3, "m").unix());
      } else {
        toast.warn(res.data.message);
      }
    } catch (error) {
      if (error.response) {
        toast.error("Please enter the correct Email");
      } else {
        toast.error(error.message);
      }
    }
  };
  const email = window.sessionStorage.getItem("email");
  const minute = auth.timeLeft?.minutes?.toString();
  const second = auth.timeLeft?.seconds?.toString();
  console.log("auth.timeLeft-==-=-=-=", auth.timeLeft);
  return (
    <>
      <Page title="Verify OTP">
        <Grid className="d-flex height100" style={{ marginBottom: "20px" }}>
          <Box className="loginForm">
            <Box className="signupbox">
              <Grid container direction={"column"}>
                <Grid item className={classes.TopText}>
                  <Typography variant="h3" className={classes.TitleOtp}>
                    One Time Password
                  </Typography>
                  <Typography className={classes.subTitleOtp}>
                    Enter One Time Password sent to your registered
                    <br />
                    email address.
                  </Typography>
                </Grid>
                <Formik
                  onSubmit={(values) => handleFormSubmit(values)}
                  initialValues={{
                    otp: "",
                  }}
                  initialStatus={{
                    success: false,
                    successMsg: "",
                  }}
                  validationSchema={yep.object().shape({
                    otp: yep
                      .string()
                      .required("OTP is required")
                      .matches(/^[0-9]*$/, "Must be a valid OTP")
                      .max(6, "Should not exceeds 6 digits")
                      .min(6, "Must be only 6 digits"),
                  })}
                >
                  {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    touched,
                    values,
                    setFieldValue,
                  }) => (
                    <Form style={{ marginTop: "20px" }}>
                      <Grid item className="fullwidth">
                        <Box>
                          <Box
                            style={{
                              display: "flex",
                              width: "100%",
                              justifyContent: "center",
                              gap: "20px",
                            }}
                          >
                            <FormControl
                              fullWidth
                              className={classes.otpFormControl}
                              error={Boolean(touched.otp && errors.otp)}
                            >
                              <OTPInput
                                value={otp}
                                inputVariant="standard"
                                autoComplete="off"
                                onChange={setOTP}
                                name="otp"
                                id="inputID"
                                error={Boolean(touched.otp && errors.otp)}
                                onBlur={handleBlur}
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  width: "100%",
                                  gap: "15px",
                                }}
                                autoFocus
                                OTPLength={6}
                                otpType="number"
                              />
                            </FormControl>
                          </Box>
                          <div>
                            <FormHelperText
                              error
                              style={{
                                fontSize: "12px",
                                fontFamily: "Inter",
                              }}
                            >
                              {touched.otp && errors.otp}
                            </FormHelperText>
                            <Box
                              style={{
                                display: "flex",
                                justifyContent: "end",
                              }}
                            >
                              {auth.timeLeft?.minutes > 0 ||
                              auth.timeLeft?.seconds > 0 ? (
                                <>
                                  <Box>
                                    <Typography
                                      variant="body1"
                                      style={{
                                        color: "#EA4335",
                                        fontFamily: "Inter",
                                        fontSize: "13px",
                                        fontStyle: "normal",
                                        fontWeight: "500",
                                        lineHeight: "24px",
                                        marginRight: "42px",
                                      }}
                                    >
                                      {minute.length > 1
                                        ? minute
                                        : "0" + minute}{" "}
                                      :{" "}
                                      {second.length > 1
                                        ? second
                                        : "0" + second}
                                    </Typography>{" "}
                                  </Box>
                                </>
                              ) : (
                                <>
                                  <Button
                                    style={{
                                      color: "#EA4335",
                                      fontFamily: "Inter",
                                      fontSize: "13px",
                                      fontStyle: "normal",
                                      fontWeight: "500",
                                      lineHeight: "24px",
                                      marginRight: "27px",
                                    }}
                                    // fullWidth
                                    onClick={() => {
                                      resetotphandle();
                                    }}
                                    disabled={
                                      auth.timeLeft && auth.timeLeft.seconds > 0
                                    }
                                  >
                                    {isLoading ? (
                                      <PageLoaderComponent
                                        loading={isLoading}
                                      />
                                    ) : (
                                      "Resend OTP"
                                    )}
                                  </Button>
                                  &nbsp;
                                </>
                              )}{" "}
                            </Box>
                          </div>
                        </Box>
                      </Grid>

                      <Box className={classes.SubmitBtnBox}>
                        <Button
                          style={{ marginTop: "20px", padding: "10px" }}
                          onClick={handleFormSubmit}
                          // disabled={isLoading}
                          // className={classes.buttonboxOtp}
                          disabled={otp === "" || isLoading}
                          className={
                            otp === "" || isLoading
                              ? classes.buttonboxdisabled
                              : classes.buttonboxOtp
                          }
                        >
                          Submit
                          {isLoading && <ButtonCircularProgress />}
                          <PageLoaderComponent loading={isLoading} />
                        </Button>
                        &nbsp;&nbsp;
                      </Box>
                    </Form>
                  )}
                </Formik>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Page>
    </>
  );
}

export default VerifyLoginOtp;

import React, { useState, useContext, useEffect } from "react";
import "src/scss/main.css";
import {
  Box,
  Typography,
  TextField,
  Grid,
  Button,
  makeStyles,
  Link,
  FormHelperText,
  FormControl,
  InputAdornment,
  IconButton,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogContent,
} from "@material-ui/core";
import { useHistory, Link as RouterComponent } from "react-router-dom";
import { Form, Formik } from "formik";
import * as yep from "yup";
import Axios from "axios";
import ApiConfig from "src/config/APICongig";
import { toast, ToastContainer } from "react-toastify";
import ButtonCircularProgress from "src/component/ButtonCircularProgress";
import { AuthContext } from "src/context/Auth";
import Page from "src/component/Page";
import { UserContext } from "src/context/User";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import VerifyOtp from "../../auth/otpVerify/index";
import VerifyLoginOtp from "./VerifyLoginOtp";
import moment from "moment";
const useStyles = makeStyles((theme) => ({
  buttonbox: {
    fontFamily: "Inter !important",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    textAlign: "center",
    textTransform: "none !important",
    letterSpacing: "0.2px",
    color: "#16110D !important",
    lineHeight: "normal",
    background: "#FFBF00 !important",
    width: "100%",
    maxWidth: "460px",
    height: "50px",
    borderRadius: "5px",
  },
  buttonboxdisabled: {
    fontFamily: "Inter !important",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    textAlign: "center",
    textTransform: "none !important",
    letterSpacing: "0.2px",
    color: "#16110D !important",
    lineHeight: "normal",
    background: "#FFBF00 !important",
    width: "100%",
    maxWidth: "460px",
    height: "50px",
    borderRadius: "5px",
    opacity: "0.5",
    cursor: "pointer",
  },
  LoginTextBox: {
    background: "rgba(113, 113, 113, 0.30) !important",
    borderRadius: "10px",
    fontSize: "16px",
    height: "50px",
    color: "var(--Secondary-Grey, #929292) !important",
    "&::placeholder": {
      color: "var(--Secondary-Grey, #929292) !important",
      fontFamily: "Inter !important",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "16px",
      lineHeight: "normal",
    },
    "& .MuiInputBase-input": {
      color: "#fff !important",
      fontFamily: "Inter !important",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "normal",
      height: "25px",
    },
  },
  wrongPassword: {
    background: "rgba(113, 113, 113, 0.30) !important",
    borderRadius: "5px",
    border: "1px solid red",
    height: "50px",
  },
  LoginTitle: {
    fontFamily: "Inter",
    fontSize: "34px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "normal",
    letterSpacing: "0.3px",
    textTransform: "capitalize",
    color: "var(--White, #FFF)",
  },
  TopText: {
    display: "grid",
    gap: "10px",
    alignItems: "center",
  },
  linkText: {
    color: "var(--White, #FFF)",
    fontFamily: "Inter",
    fontSize: "14px !important",
    fontStyle: "normal",
    fontWeight: "600 !important",
    lineHeight: "normal",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  SubmitBtnBox: {
    textAlign: "center",
    marginTop: "20px",
  },
  label: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "24px",
    marginTop: "15px !important",
    marginBottom: "5px !important",
    color: "#D9D9D9",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  rememberMe: {
    marginTop: "0px",
    "&.MuiTypography-body1": {
      color: "var(--White, #FFF) !important",
      fontFamily: "Inter",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "500",
      lineHeight: "normal",
      [theme.breakpoints.down("sm")]: {
        fontSize: "12px",
      },
    },
  },
  Logintextfiled: {
    fontFamily: "Inter !important",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "normal",
    color: "var(--Secondary-Grey, #929292) !important",
    background: "rgba(113, 113, 113, 0.30) !important",
  },
  LoginSubTitle: {
    color: "rgba(11, 20, 38, 0.5)",
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400 !important",
    lineHeight: "24px",
  },
  Loginlabelall: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "normal",
    color: "var(--White, #FFF)",
  },
  checkedlogin: {
    "&.MuiCheckbox-colorSecondary.Mui-checked": {
      color: "var(--White, #FFF) !important",
    },
    "&.MuiCheckbox-root": {
      color: "var(--White, #FFF) !important",
    },
  },
  dontacctypo: {
    color: "var(--White, #FFF)",
    textAlign: "center",
    fontFamily: "Inter",
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "24px",
  },
  customInput: {
    "&::placeholder": {
      color: "#fff !important", // Change the placeholder text color
    },
  },
  passsec: {
    "& svg": {
      color: "#fff",
    },
  },
}));

function Login(props) {
  const auth = useContext(AuthContext);
  const user = useContext(UserContext);
  const classes = useStyles();
  const [isloading, setLoader] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [Ip, setIP] = useState("");
  const [deviceBrower, setDeviceBowser] = useState("");
  const [isRememberMe, setIsRememberMe] = useState(false);
  const [wrongPass, setWrongPass] = useState(false);
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const formInitialSchema = isRememberMe
    ? {
        email: "",
        password: "",
      }
    : {
        email: window.sessionStorage.getItem("email")
          ? window.sessionStorage.getItem("email")
          : "",
      };

  const handleFormSubmit = async (values) => {
    sessionStorage.setItem("email", values.email);
    setLoader(true);

    try {
      const res = await Axios({
        method: "POST",
        url: ApiConfig.login,
        data: {
          email: values.email,
          password: values.password,
        },
      });

      if (res.data.responseCode === 200) {
        toast.success(res.data.responseMessage);
        setLoader(false);
        if (res.data.result.otpVerification == false) {
          auth.setEndtime(moment().add(3, "m").unix());
          setOpen(true);
        } else {
          auth.userLogIn(true, res.data?.result?.token); 
          window.localStorage.setItem("token", res.data.result.token);
          history.push("/dashboard");
         
        }
      } else if (res.data.status === 500) {
        setLoader(false);
        toast.error(
          "Cannot reach internet. Please check your device internet connections."
        );
      }
    } catch (error) {
      console.log("ERROR", error.response);
      setLoader(false);
      if (error.res) {
        toast.error("Email or Password not Correct");
      } else if (error.response.data.responseCode === 400) {
      } else {
        toast.error(error.response.data.responseMessage);
      }
    }
  };

  // useEffect(() => {
  //   const { detect } = require("detect-browser");
  //   const browser = detect();
  //   if (browser) {
  //     setDeviceBowser(browser?.name);
  //   }
  // }, []);

  useEffect(() => {
    if (window.sessionStorage.getItem("email")) {
      setIsRememberMe(true);
    } else {
      setIsRememberMe(false);
    }
  }, [window.sessionStorage.getItem("email")]);

  function rememberMe() {
    if (!isRememberMe) {
      setIsRememberMe(true);
      const email = document.getElementById("email");
      window.sessionStorage.setItem("email", email?.value);
    } else {
      setIsRememberMe(false);
      window.sessionStorage.removeItem("email");
      window.localStorage.removeItem("password");
    }
  }

  return (
    <>
      {open && (
        <VerificationOtpModal open={open} setOpen={(item) => setOpen(item)} />
      )}
      <Page title="Login">
        <Grid className="d-flex height100">
          <Box className="loginForm">
            <Box>
              <Grid container direction={"column"}>
                <Grid item className={classes.TopText}>
                  <Typography variant="h2" className={classes.LoginTitle}>
                    Welcome back! Glad to see you, Again!
                  </Typography>
                </Grid>

                <Formik
                  onSubmit={(values) => handleFormSubmit(values)}
                  initialValues={formInitialSchema}
                  initialStatus={{
                    success: false,
                    successMsg: "",
                  }}
                  validationSchema={yep.object().shape({
                    email: yep
                      .string()
                      .required("Please enter your Email Address"),
                    password: yep
                      .string()
                      .required("Please enter your Password"),
                  })}
                >
                  {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    touched,
                    values,
                    setFieldValue,
                  }) => (
                    <Form style={{ marginTop: "25px" }}>
                      <Grid item>
                        <label className={classes.Loginlabelall}>
                          Email <span style={{ color: "#EA4335" }}>*</span>
                        </label>

                        <TextField
                          placeholder="Enter your email."
                          type="email"
                          variant="outlined"
                          fullWidth
                          id="inputID"
                          size="small"
                          inputProps={{ maxLength: 256 }}
                          value={values.email}
                          name="email"
                          className={classes.LoginTextBox}
                          error={Boolean(touched.email && errors.email)}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          onSubmit={handleSubmit}
                          InputProps={{
                            className: classes.Logintextfiled,
                            classes: { input: classes.customInput },
                          }}
                        />
                        <FormHelperText
                          error
                          style={{
                            fontSize: "12px",
                            fontFamily: "Inter",
                          }}
                        >
                          {touched.email && errors.email}
                        </FormHelperText>
                      </Grid>
                      <Grid item style={{ marginTop: "16px" }}>
                        <FormControl
                          fullWidth
                          style={{ marginTop: "0px !important" }}
                        >
                          <Box
                            style={{ width: "100%" }}
                            className={classes.loginForm1}
                          >
                            <label className={classes.Loginlabelall}>
                              Password{" "}
                              <span style={{ color: "#EA4335" }}>*</span>
                            </label>
                            <TextField
                              placeholder="Enter your password."
                              size="small"
                              variant="outlined"
                              fullWidth
                              id="inputID"
                              type={showPassword ? "text" : "password"}
                              value={values.password}
                              inputProps={{ minLength: 8, maxLength: 20 }}
                              name="password"
                              error={Boolean(
                                touched.password && errors.password
                              )}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              onSubmit={handleSubmit}
                              InputProps={{
                                classes: { input: classes.customInput },
                                className:
                                  wrongPass === true
                                    ? classes.wrongPassword
                                    : classes.LoginTextBox,
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      style={{ color: "#fff" }}
                                      onClick={() =>
                                        setShowPassword(!showPassword)
                                      }
                                      edge="end"
                                    >
                                      <>
                                        {showPassword ? (
                                          <Visibility />
                                        ) : (
                                          <VisibilityOff />
                                        )}
                                      </>
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                            <FormHelperText
                              error
                              style={{
                                fontSize: "12px",
                                fontFamily: "Inter",
                              }}
                            >
                              {touched.password && errors.password}
                            </FormHelperText>
                          </Box>
                        </FormControl>

                        <Box className={classes.SubmitBtnBox} mt={2}>
                          <Button
                            type="submit"
                            // className={classes.buttonbox}
                            // disabled={isloading}
                            disabled={
                              values.email === "" ||
                              values.password === "" ||
                              isloading
                            }
                            className={
                              values.email === "" ||
                              values.password === "" ||
                              isloading
                                ? classes.buttonboxdisabled
                                : classes.buttonbox
                            }
                          >
                            Log In
                            {isloading && <ButtonCircularProgress />}
                          </Button>
                        </Box>
                      </Grid>

                      <Grid>
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          style={{
                            marginTop: "10px",
                            alignItems: "center",
                            paddingLeft: "6px",
                          }}
                        >
                          <FormControlLabel
                            className={classes.rememberMe}
                            control={
                              <Checkbox
                                name="checkedC"
                                className={classes.checkedlogin}
                                checked={isRememberMe}
                                onClick={rememberMe}
                                style={{ fontSize: "14px" }}
                              />
                            }
                            label="Remember me"
                          />
                          <Typography variant="body2">
                            <Link
                              component={RouterComponent}
                              to="/forget-password"
                              className={classes.linkText}
                            >
                              Forgot Password?
                            </Link>
                          </Typography>
                        </Box>
                        <Box
                          style={{
                            display: "grid",
                            justifyContent: "center",
                            marginTop: "26px",
                          }}
                        >
                          <Typography classname={classes.dontacctypo}>
                            Don’t have an account?{" "}
                            <span
                              onClick={() => {
                                history.push("/register");
                              }}
                              style={{
                                cursor: "pointer",
                                fontWeight: "700",
                                color: "var(--White, #FFF)",
                                textAlign: "center",
                                fontFamily: "Inter",
                                fontSize: "14px !important",
                                textDecorationLine: "underline",
                              }}
                            >
                              Create One
                            </span>
                          </Typography>
                        </Box>
                      </Grid>
                    </Form>
                  )}
                </Formik>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Page>
    </>
  );
}

export default Login;

const VerificationOtpModal = ({ open, setOpen }) => {
  return (
    <Dialog open={open} fullWidth maxWidth="sm">
      <DialogContent>
        <VerifyLoginOtp type="login" />
      </DialogContent>
    </Dialog>
  );
};

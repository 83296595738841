import React from "react";
import { makeStyles, Box, LinearProgress } from "@material-ui/core";
import SettingsContext from "src/context/SettingsContext";

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: "center",
    backgroundColor: "#131313",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "center",
    left: 0,
    // padding: theme.spacing(3),
    position: "fixed",
    top: 0,
    width: "100%",
    zIndex: 2000,
    // backgroundColor: "#fff",
  },
  loader1: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  loader: {
    maxWidth: "500px",
    padding: "0px 30px",
  },
}));

export default function PageLoading() {
  const classes = useStyles();
  const themeSeeting = React.useContext(SettingsContext);

  return (
    <div className={classes.root}>
      <Box className={classes.loader1}>
        <Box className={classes.loader}>
       
              <img
                src="images/HeadLogo.png"
           
                alt="loader"
                style={{ cursor: "pointer",width:"100%",maxWidth:"161px" }}
              />
        
        </Box>
      </Box>
    </div>
  );
}

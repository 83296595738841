import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import DashboardLayout from "src/layouts/DashboardLayout";

import LoginLayout from "src/layouts/LoginLayout";

export const routes = [
  {
    exact: true,
    path: "/",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/login/index")),
  },

  {
    exact: true,
    path: "/register",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/register/index")),
  },

  {
    exact: true,
    path: "/forget-password",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/Forget/index")),
  },
  {
    exact: true,
    path: "/reset-password",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/Reset Password/index")),
  },
  {
    exact: true,
    path: "/otp-verify",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/otpVerify/index")),
  },
  {
    exact: true,
    path: "/dashboard",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/dashboard")),
  },
  {
    exact: true,
    path: "/node-activation",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/nodeActivation")),
  },
  {
    exact: true,
    path: "/api-key",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/apiKey")),
  },
  {
    exact: true,
    path: "/profile",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/profile/profile.js")),
  },
  {
    exact: true,
    path: "/edit-profile",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/profile/editProfile.js")),
  },
  {
    exact: true,
    path: "/change-password",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/ChangePassword/ChangePassword")
    ),
  },
  {
    exact: true,
    path: "/buy-node",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/BuyNode/index")),
  },
  {
    exact: true,
    path: "/order-history",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/OrderHistory/index")),
  },
  {
    exact: true,
    path: "/terms&condition",
    // guard: true,
    // layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/StaticContent/termsCondition")
    ),
  },
  {
    exact: true,
    path: "/termscondition",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/StaticContent/termsCondition")
    ),
  },
  {
    exact: true,
    path: "/privacy&policy",
    // guard: true,
    // layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/StaticContent/privacyPolicy")
    ),
  },
  {
    exact: true,
    path: "/privacypolicy",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/StaticContent/privacyPolicy")
    ),
  },
  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },
  {
    component: () => <Redirect to="/404" />,
  },
];

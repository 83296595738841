import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles, Grid, Box } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { FiSun } from "react-icons/fi";
import SettingsContext from "src/context/SettingsContext";
import { FaRegMoon } from "react-icons/fa";
const useStyles = makeStyles((theme) => ({
  content: {
    minHeight: "fit-content",
    maxWidth: "508px",
    overflowY: "auto",
    borderRadius: "10px",
    padding: "15px",
    color: "var(--White, #FFF)",
    margin: "auto",
    // background: "#FFF",
    // boxShadow: "3px 4px 9px 0px rgba(0, 0, 0, 0.25)",
    marginBottom: "20px",
    marginTop: "25px",
    // marginTop: "70px",
    "&::-webkit-scrollbar": {
      width: "5px",
    },
    "&::-webkit-scrollbar-track": {
      margin: "40px 0 40px 0",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#FD9F49",
      borderRadius: "20px !important",
    },
    "@media(max-width:600px)": {
      padding: "20px",
    },
  },
  left: {
    height: "100%",
    "@media(max-width:959px)": {
      display: "none",
    },
  },
  mainScreenBack: {
    overflow: "hidden",
  },
  logo: {
    cursor: "pointer",
    width: "149px",
    padding: "20px",
    position: "absolute",
    top: "0",
    zIndex: "-1",
  },
  imageside: {
    width: "100%",
    height: "100vh",
    "@media (max-width: 950px)": {
      display: "none !important",
    },
  },
  firstscreen: {
    background: "#252525",
    overflow: "auto",
    height: "100%",
  },
}));

const LoginLayout = ({ children }) => {
  const classes = useStyles();
  const history = useHistory();
  const themeSeeting = React.useContext(SettingsContext);

  const changeTheme = (type) => {
    themeSeeting.saveSettings({
      theme: type,
    });
  };
  const [themeChange, seThemeChange] = useState("");
  useEffect(() => {
    const activeTheme = themeSeeting?.themekey?.theme;
    seThemeChange(activeTheme);
  }, []);
  return (
    <Box className={classes.mainScreenBack}>
      <Grid container style={{ height: "100vh" }}>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          className={classes.firstscreen}
        >
          <Box align="center" style={{ marginTop: "40px" }}>
            <img
              onClick={() => history.push("/")}
              style={{ cursor: "pointer", width: "100%", maxWidth: "200px" }}
              src="images/HeadLogo.png"
              alt="dashboard layout logo"
            />
          </Box>

          <Box>
            <Box className={classes.content}>{children}</Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} className={classes.imageside}>
          <Box>
            <img
              className={classes.imageside}
              src="/images/Sideimage.png"
              alt="Side_Image"
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

LoginLayout.propTypes = {
  children: PropTypes.node,
};

export default LoginLayout;
